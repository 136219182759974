import { useGlobalsStore } from '~/stores/globals';
import { useCabinetStore } from '~/stores/cabinet';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) {
    return;
  }
  
  const { setNotification } = useGlobalsStore();
  const { validateConformationEmail } = useCabinetStore();
  const code = to.query.code;

  if (!code) {
    throw createError({
      statusCode: 404,
      message: 'Page not found',
      fatal: true,
    });
  }

  const responseError = await validateConformationEmail(String(code));

  if (responseError) {
    switch (responseError) {
      case 4026:
        setNotification({
          icon: 'notification-green',
          dictionaryKeyText: 'cabinet_personal_data_error_4026',
          timeout: -1,
        });
        break;
      case 4027:
        setNotification({
          icon: 'notification-green',
          dictionaryKeyText: 'cabinet_personal_data_error_4027',
          timeout: -1,
        });
        break;
    }
  }

  return navigateTo('/');
});